<template>
  <div class="padd20">
    <div style="width: 100%; text-align: right; padding-top: 2rem; box-sizing: border-box; display: flex;">
      <div id=""
        style="color: #FFFFFF; font-size: 1.8rem; box-sizing: border-box; padding: 1rem; background-color: #008080; border-radius: 1rem; margin-right: 2rem;color: #ffffff; cursor: pointer;" @click="Ed()">
        确认修改
      </div>
      <div id=""
        style="color: #FFFFFF; font-size: 1.8rem; box-sizing: border-box; padding: 1rem; background-color: #ffffff;color: #008080; border-radius: 1rem; cursor: pointer;"
        @click="back()">
        返回
      </div>
    </div>
    <div style="margin-top: 2rem;">
      <mapselect :mapcenter="centerLatLng" :oldmarker="oldMarker" @mapclick="pointChange"></mapselect>
    </div>
  </div>

</template>
<script charset="utf-8"
  src="https://map.qq.com/api/js?v=2.exp&key=ZZ4BZ-NR7CG-QO6QZ-IQAMA-ISKVE-YKF7E&sig=R8fIA32GoNXLoAPdYYgu5oUMaxEmsWRr">
</script>
<script type="text/ecmascript-6">
  import qqMapSelectPoint from './selectPoint.vue'
  export default {
    components: {
      mapselect: qqMapSelectPoint
    },
    data: function() {
      return {
        pointName: '',
        qqmap: null,
        centerLatLng: '34.759666,113.648071',
        oldMarker: '34.759666,113.648071',
        newMarker: null,
        id: null,
        equipmentId:null,
        latitude:null,
        longitude:null
      }
    },
    mounted() {},
    created() {
      this.id = this.$route.query.id
      this.Data()
    },
    methods: {
      Ed(){
        let obj = {
          	"equipmentId": this.equipmentId,
          	"latitude": this.latitude,
          	"longitude": this.longitude
        }
        this.$newPost('/construction/address/update',obj).then(res => {
          if (res.code == 2000) {
            this.$message({
              message: res.message,
              type: 'success'
            })
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
      },
      Data() {
        this.$newGet('/construction/address/'+ this.id).then(res => {
          console.log(res, 'xxx')
          if (res.code == 2000) {
            this.equipmentId = res.data.equipmentId
            this.centerLatLng = res.data.latitude + ',' + res.data.longitude
            this.oldMarker = res.data.latitude + ',' + res.data.longitude
            this.latitude = res.data.latitude
            this.longitude = res.data.longitude
          } else {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }
        })
      },
      pointChange(ev) {
        console.log('捕获到点击坐标', ev)
        this.latitude = ev.lat
        this.longitude = ev.lng
      },
      back() {
        this.$router.go(-1); //返回上一层
      }
    }
  }
</script>

<style>
  .qqmap {
    width: 100%;
    height: 600px;
  }
</style>
